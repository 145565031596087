import React, { useContext, useEffect } from 'react'
import { AuthContext } from '../context/AuthContext'
import Session from './Session'
import { SelectedTestProvider } from '../context/SelectedTest'
import { ModeContext } from '../context/ModeContext'
import Loading from '../component/commonUnit/Loading'
import { getSessionById, joinSession } from '../service/sessionService'
import { useState } from 'react'
import { useResizable } from 'react-resizable-layout'
import '../css/codeIssuePanel.scss'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { validateToken } from '../service/authService'
import { GroupsProvider } from '../context/GroupsContext'
import { GroupsFilterProvider } from '../context/GroupsFilterContext'
import { ErrorContextProvider } from '../context/ErrorContext'
import { TestResultContextProvider } from '../context/TestResultContext'
import { AutoGroupingContextProvider } from '../context/AutoGroupingContext'
import { PresenterListProvider } from '../context/PresenterListContext'
import { CurrentStepContextProvider } from '../context/CurrentStepContext'
import { MessageProvider } from '../context/MessageContext'
import { SubmissionsProvider } from '../context/SubmissionsContext'
import HeaderBanner from '../component/topBar/HeaderBanner'
import BasicTabs from '../component/commonUnit/TabPanels'
import StudentPanel from '../component/student-components/StudentPanel'
import AudioStu from '../component/audioSTU/AudioStu'
import AudioSessionPanel from '../component/audioSTU/AudioSessionPanel'
import { addStu, addStuToSession, getStuById } from '../service/studentService'
import { SessionContext } from '../context/SessionContext'

const Home = React.memo(() => {
  const { currentUser, setCurrentUser } = useContext(AuthContext)
  const { Mode } = useContext(ModeContext)
  // const [session, setSession] = useState(null)
  const { session, setSession } = useContext(SessionContext)
  const navigate = useNavigate()
  const {
    isDragging: isCodeIssueDragging,
    position: codeIsueW,
    separatorProps: codeIssueSeparatorProps,
  } = useResizable({
    axis: 'x',
    initial: 600,
    min: 0,
  })

  useEffect(() => {
    const sessionId = window.location.pathname.split('/').pop()
    const fetchSession = async () => {
      getSessionById(sessionId).then((session) => {
        setSession(session)
      })
    }
    fetchSession()
    joinSession(sessionId, currentUser.id, currentUser.uid)
  }, [])

  return (
    <div className="home">
      <SelectedTestProvider>
        <GroupsProvider sessionId={session?.id}>
          <GroupsFilterProvider>
            <ErrorContextProvider>
              <TestResultContextProvider>
                <AutoGroupingContextProvider>
                  <HeaderBanner />
                  <PresenterListProvider>
                    <CurrentStepContextProvider>
                      <MessageProvider sessionId={session?.id}>
                        <div className="container">
                          {currentUser?.role <= 2 &&
                            session?.type === 'Audio' && <AudioSessionPanel />}
                          {currentUser?.role <= 2 &&
                            session?.type !== 'Audio' && (
                              <>
                                <SubmissionsProvider>
                                  <BasicTabs />
                                </SubmissionsProvider>
                              </>
                            )}

                          {currentUser.role === 3 &&
                            session?.type === 'Audio' && <AudioStu />}
                          {currentUser?.role === 3 &&
                            session?.type !== 'Audio' && <StudentPanel />}
                        </div>
                      </MessageProvider>
                    </CurrentStepContextProvider>
                  </PresenterListProvider>
                </AutoGroupingContextProvider>
              </TestResultContextProvider>
            </ErrorContextProvider>
          </GroupsFilterProvider>
        </GroupsProvider>
      </SelectedTestProvider>
    </div>
  )
})

export default Home
