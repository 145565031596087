import axios from 'axios'

class AxiosManager {

  constructor() {
    this.axiosInstance = axios.create({
      timeout: 10000,
    })

    if (typeof window !== 'undefined') {
      const token = localStorage.getItem('accessToken')
      if (token) {
        this.setAuthToken(token)

        this.verifyToken(token).catch(() => {
          localStorage.removeItem('accessToken')
          delete this.axiosInstance.defaults.headers.common['Authorization']
        })
      }
    }
  }

  async verifyToken (token) {
    // try {
    //   await this.axiosInstance.post('/auth/verify', { token })
    // } catch (error) {
    //   throw new Error('Invalid token')
    // }
  }

  static getInstance () {
    if (!AxiosManager.instance) {
      AxiosManager.instance = new AxiosManager()
    }
    return AxiosManager.instance
  }

  getAxiosInstance () {
    return this.axiosInstance
  }

  setAuthToken (token) {
    this.axiosInstance.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${token}`
    if (typeof window !== 'undefined') {
      localStorage.setItem('accessToken', token)
    }
  }


  clearAuthToken () {
    delete this.axiosInstance.defaults.headers.common['Authorization']
    if (typeof window !== 'undefined') {
      localStorage.removeItem('accessToken')
    }
  }
}


export const axiosInstance = AxiosManager.getInstance().getAxiosInstance()

export const axiosManager = AxiosManager.getInstance()