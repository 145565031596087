import { createContext, useEffect, useState } from "react"
import {
  signIn,
  signUp,
  signOut,
  getCurrentUser,
  fetchAuthSession,
  resetPassword,
  confirmResetPassword
} from 'aws-amplify/auth'
import { AuthUser } from 'aws-amplify/auth'
import { Hub } from '@aws-amplify/core'
import { axiosInstance, axiosManager } from '../service/axiosManager'
import { Amplify } from 'aws-amplify'
import { getUserByUid } from '../service/userService'

export const AuthContext = createContext()


Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: 'us-east-2_W8iffbshV',
      userPoolClientId: '4l70fghng5q9ncjm3npcs4pokk'
    }
  }
})

//Context for authentication
export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)
  const [currentUserLoading, setCurrentUserLoading] = useState(true)
  const [passwordResetState, setPasswordResetState] = useState({
    email: '',
    isCodeSent: false,
    error: null
  })

  useEffect(() => {
    checkAuth()

    const listener = Hub.listen('auth', ({ payload }) => {
      const { event } = payload
      if (event === 'signedIn') {
        checkAuth()
      } else if (event === 'signedOut') {
        setCurrentUser(null)
        setCurrentUserLoading(false)
      }
    })

    return () => {
      listener()
    }
  }, [])

  const checkAuth = async () => {
    try {
      const user = await getCurrentUser()
      const session = await fetchAuthSession()

      if (session.tokens?.accessToken) {
        axiosManager.setAuthToken(session.tokens.accessToken.toString())
      }

      const userInfo = await getUserByUid(user.userId)
      if (userInfo) {
        setCurrentUser(userInfo)
        setCurrentUserLoading(false)
        console.log('User info loaded:', userInfo)
      } else {
        console.error('Failed to get user info')
        throw new Error('User info not found')
      }
    } catch (error) {
      console.error('CheckAuth error:', error)
      setCurrentUser(null)
      setCurrentUserLoading(false)
      axiosManager.clearAuthToken()
    }
  }

  const handleSignIn = async (email, password) => {
    try {
      try {
        await signOut()
        axiosManager.clearAuthToken()
      } catch (error) {
        console.log('Sign out error:', error)
      }

      const result = await signIn({
        username: email,
        password,
      })

      await checkAuth()
      return result
    } catch (error) {
      console.error('Sign in error:', error)
      throw error
    }
  }

  const handleSignUp = async (userInfo) => {
    const response = await axiosInstance.post(process.env.REACT_APP_HOST_API + '/signup', {
      user: userInfo,
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(response.data.message)
    }
  }

  const handleSignOut = async () => {
    try {
      await signOut()
      setCurrentUser(null)
      setCurrentUserLoading(false)
      axiosManager.clearAuthToken()
    } catch (error) {
      console.error('Error signing out:', error)
      throw error
    }
  }

  const handleForgotPassword = async (email) => {
    try {
      await resetPassword({ username: email })
      setPasswordResetState({
        email,
        isCodeSent: true,
        error: null
      })
      return { success: true }
    } catch (error) {
      console.error('Forgot password error:', error)
      let errorMessage = 'An error occurred while processing your request.'

      if (error.name === 'UserNotFoundException') {
        errorMessage = 'Email address not found in our system.'
      } else if (error.name === 'LimitExceededException') {
        errorMessage = 'Too many attempts. Please try again later.'
      } else if (error.name === 'InvalidParameterException') {
        errorMessage = 'Please provide a valid email address.'
      }

      setPasswordResetState({
        email: '',
        isCodeSent: false,
        error: errorMessage
      })
      throw error
    }
  }

  const handleConfirmPasswordReset = async (email, code, newPassword) => {
    try {
      await confirmResetPassword({
        username: email,
        confirmationCode: code,
        newPassword
      })

      setPasswordResetState({
        email: '',
        isCodeSent: false,
        error: null
      })

      return { success: true }
    } catch (error) {
      console.error('Confirm password reset error:', error)
      let errorMessage = 'An error occurred while resetting your password.'

      if (error.name === 'CodeMismatchException') {
        errorMessage = 'Invalid verification code. Please try again.'
      } else if (error.name === 'ExpiredCodeException') {
        errorMessage = 'Verification code has expired. Please request a new one.'
      } else if (error.name === 'InvalidPasswordException') {
        errorMessage = 'Password does not meet requirements. Password should be at least 8 characters long and contain uppercase, lowercase, numbers and special characters.'
      } else if (error.name === 'LimitExceededException') {
        errorMessage = 'Too many attempts. Please try again later.'
      }

      setPasswordResetState(prev => ({
        ...prev,
        error: errorMessage
      }))
      throw error
    }
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        currentUserLoading,
        setCurrentUserLoading,
        handleSignIn,
        handleSignUp,
        handleSignOut,
        handleForgotPassword,
        handleConfirmPasswordReset,
        passwordResetState
      }}>
      {children}
    </AuthContext.Provider>
  )
}
