import axios from 'axios'
import Cookies from 'js-cookie'
const url = process.env.REACT_APP_HOST_API


export const signInWithEmailAndPassword = async (email, password) => {
  const instructor = {
    email: email,
    password: password
  }
  let token
  try {
    let res = await axios.post(url + '/login', instructor)
    token = res.data.token
    Cookies.set('VizPI_token', token, { expires: 7 })
  } catch (e) {
    console.error(e)
    throw e
  }
  return token
}

export const validateToken = async (token) => {
  let instructor
  await axios.post(url + '/validate', {
    token: token
  }).then((response) => {
    instructor = response.data
    if (instructor.role == 1) {
      instructor.session_id = instructor.current_session
    }
  }).catch((error) => {
    throw error
  })
  return instructor
}
export const changeInstructorPassword = async (currentPassword, newPassword, email) => {
  if (!email) {
    throw new Error("Email is required")
  }

  try {
    let res = await axios.post(process.env.REACT_APP_HOST_API + '/changePassword', {
      email,
      currentPassword,
      newPassword
    })

    if (res.data.success) {
      return res.data
    } else {
      throw new Error(res.data.message || "Failed to change password")
    }
  }
  catch (error) {
    console.error("Error changing password:", error.response?.data?.message || error.message)
    throw new Error(error.response?.data?.message || "Failed to change password")
  }
}
export const changeStudentPassword = async (currentPassword, newPassword, studentID) => {
  if (!newPassword) {
    throw new Error("New password is required")
  }

  try {
    let res = await axios.post(process.env.REACT_APP_HOST_API + '/changeStudentPassword', {
      currentPassword,
      newPassword,
      studentID
    })
    if (res.data.success) {
      console.log("passowrd updated successfully")
      return res.data
    } else {
      throw new Error(res.data.message || "Failed to change password")
    }
  }
  catch (error) {
    console.error("Error changing email:", error.response?.data?.message || error.message)
    throw new Error(error.response?.data?.message || "Failed to change password")
  }
}


export const updateInstructorEmail = async (currentEmail, newEmail) => {
  if (!newEmail) {
    throw new Error("New email is required")
  }

  console.log({
    currentEmail: currentEmail,
    newEmail: newEmail
  })

  try {
    let res = await axios.post(process.env.REACT_APP_HOST_API + '/changeInstructorEmail', {
      currentEmail,
      newEmail
    })

    if (res.data.success) {
      console.log("Email updated successfully")
      return res.data
    } else {
      throw new Error(res.data.message || "Failed to change email")
    }
  }
  catch (error) {
    console.error("Error changing email:", error.response?.data?.message || error.message)
    throw new Error(error.response?.data?.message || "Failed to change email")
  }
}
export const updateStudentEmail = async (currentEmail, newEmail, studentID) => {
  if (!newEmail) {
    throw new Error("New email is required")
  }

  console.log({
    currentEmail: currentEmail,
    newEmail: newEmail,
    studentID: studentID
  })

  try {
    let res = await axios.post(process.env.REACT_APP_HOST_API + '/changeStudentEmail', {
      currentEmail,
      newEmail,
      studentID
    })

    if (res.data.success) {
      console.log("Email updated successfully")
      return res.data
    } else {
      throw new Error(res.data.message || "Failed to change email")
    }
  }
  catch (error) {
    console.error("Error changing email:", error.response?.data?.message || error.message)
    throw new Error(error.response?.data?.message || "Failed to change email")
  }
}




export const sendRecoveryEmail = async (email) => {
  if (!email) {
    //console.log("can't update")
    return false
  }
  console.log({
    email: email,
  })
  try {
    let res = await axios.post(process.env.REACT_APP_HOST_API + '/recover', {
      email: email,
    })
    console.log(res)
    return res
  }
  catch (err) {
    //console.log(err)
    return err
  }
}


