import React, { useContext, useState } from 'react'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import {
  Alert,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@mui/material'

const Login = () => {
  const [err, setErr] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false)
  const [recoveryEmail, setRecoveryEmail] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [showResetForm, setShowResetForm] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()
  const {
    handleSignIn,
    handleForgotPassword,
    handleConfirmPasswordReset,
    passwordResetState,
  } = useContext(AuthContext)

  const handleHomePage = () => {
    navigate('/about')
  }

  const validateEmail = (email) => {
    return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setErr('')
    setIsLoading(true)

    // Input validation
    if (!email && !password) {
      setErr('Please enter both email and password')
      setIsLoading(false)
      return
    }
    if (!email) {
      setErr('Please enter your email address')
      setIsLoading(false)
      return
    }
    if (!password) {
      setErr('Please enter your password')
      setIsLoading(false)
      return
    }
    if (!validateEmail(email)) {
      setErr('Please enter a valid email address')
      setIsLoading(false)
      return
    }
    if (password.length < 6) {
      setErr('Password must be at least 6 characters long')
      setIsLoading(false)
      return
    }

    try {
      await handleSignIn(email, password)
      // Navigate to the intended destination or default to home
      const from = location.state?.from?.pathname || '/'
      navigate(from, { replace: true })
    } catch (err) {
      console.error(err)
      // Handle AWS Cognito specific errors
      if (err.name === 'NotAuthorizedException') {
        setErr('Incorrect username or password. Please try again.')
      } else if (err.name === 'UserNotConfirmedException') {
        setErr('Please verify your email address before signing in.')
      } else if (err.name === 'UserNotFoundException') {
        setErr('Account not found. Please check your email or sign up.')
      } else if (err.name === 'PasswordResetRequiredException') {
        setErr(
          'Password reset required. Please use the forgot password option.'
        )
      } else if (err.name === 'TooManyRequestsException') {
        setErr('Too many attempts. Please try again later.')
      } else if (err.name === 'InvalidParameterException') {
        setErr('Invalid email format. Please enter a valid email address.')
      } else if (err.response?.status === 400) {
        if (err.response?.data?.message?.includes('password')) {
          setErr('Incorrect password. Please try again.')
        } else if (err.response?.data?.message?.includes('email')) {
          setErr('Email not registered. Please check your email or sign up.')
        } else {
          setErr('Invalid email or password combination.')
        }
      } else if (err.response?.status === 401) {
        setErr('Your account has been locked. Please contact support.')
      } else if (err.response?.status === 404) {
        setErr(
          'Account not found. Please check your email or create a new account.'
        )
      } else if (err.response?.status === 429) {
        setErr('Too many login attempts. Please try again later.')
      } else if (err.message?.includes('Network Error')) {
        setErr(
          'Unable to connect to server. Please check your internet connection.'
        )
      } else {
        setErr(
          'An unexpected error occurred. Please try again later. (Error code: ' +
            (err.response?.status || err.name || 'unknown') +
            ')'
        )
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleForgotPasswordSubmit = async () => {
    if (!recoveryEmail) {
      setErr('Please enter your email address')
      return
    }

    if (!validateEmail(recoveryEmail)) {
      setErr('Please enter a valid email address')
      return
    }

    try {
      await handleForgotPassword(recoveryEmail)
      setShowResetForm(true)
    } catch (error) {
      setErr(error.message || 'Failed to send recovery email')
    }
  }

  const handlePasswordReset = async () => {
    if (!verificationCode) {
      setErr('Please enter the verification code')
      return
    }

    if (!newPassword) {
      setErr('Please enter a new password')
      return
    }

    try {
      await handleConfirmPasswordReset(
        recoveryEmail,
        verificationCode,
        newPassword
      )
      setErr('')
      setForgotPasswordOpen(false)
      setShowResetForm(false)
      setRecoveryEmail('')
      setVerificationCode('')
      setNewPassword('')
      // Show success message
      alert(
        'Password has been reset successfully. Please login with your new password.'
      )
    } catch (error) {
      setErr(error.message || 'Failed to reset password')
    }
  }

  return (
    <div className="formContainer">
      <div className="formWrapper">
        <span className="logo">VizPI</span>
        <form onSubmit={handleSubmit} className="loginForm">
          <TextField
            type="email"
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={err && err.includes('email')}
            disabled={isLoading}
          />
          <TextField
            type="password"
            label="Password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={err && err.includes('password')}
            disabled={isLoading}
          />
          {err && (
            <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
              {err}
            </Alert>
          )}
          <div className="loginButton">
            <Button
              variant="contained"
              type="submit"
              fullWidth
              sx={{ mt: 2 }}
              disabled={isLoading}>
              {isLoading ? <CircularProgress size={24} /> : 'Sign in'}
            </Button>
          </div>
          <Button
            onClick={handleHomePage}
            variant="outlined"
            fullWidth
            sx={{ mt: 2 }}
            disabled={isLoading}>
            Homepage
          </Button>
          <div style={{ textAlign: 'center', marginTop: '1rem' }}>
            <Button
              onClick={() => setForgotPasswordOpen(true)}
              color="primary"
              disabled={isLoading}>
              Forgot Password?
            </Button>
          </div>
          <p style={{ textAlign: 'center', marginTop: '1rem' }}>
            Don't have an account? <Link to="/signup">Sign up</Link>
          </p>
        </form>
      </div>

      <Dialog
        open={forgotPasswordOpen}
        onClose={() => {
          if (!isLoading) {
            setForgotPasswordOpen(false)
            setShowResetForm(false)
            setRecoveryEmail('')
            setVerificationCode('')
            setNewPassword('')
            setErr('')
          }
        }}>
        <DialogTitle>
          {showResetForm ? 'Reset Password' : 'Forgot Password'}
        </DialogTitle>
        <DialogContent>
          {!showResetForm ? (
            <TextField
              autoFocus
              margin="dense"
              label="Email Address"
              type="email"
              fullWidth
              variant="outlined"
              value={recoveryEmail}
              onChange={(e) => setRecoveryEmail(e.target.value)}
              disabled={isLoading}
              error={Boolean(err)}
            />
          ) : (
            <>
              <TextField
                margin="dense"
                label="Verification Code"
                type="text"
                fullWidth
                variant="outlined"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                disabled={isLoading}
              />
              <TextField
                margin="dense"
                label="New Password"
                type="password"
                fullWidth
                variant="outlined"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                disabled={isLoading}
                helperText="Password must be at least 8 characters long and contain uppercase, lowercase, numbers and special characters"
              />
            </>
          )}
          {err && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {err}
            </Alert>
          )}
          {passwordResetState.error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {passwordResetState.error}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setForgotPasswordOpen(false)
              setShowResetForm(false)
              setRecoveryEmail('')
              setVerificationCode('')
              setNewPassword('')
              setErr('')
            }}
            disabled={isLoading}>
            Cancel
          </Button>
          <Button
            onClick={
              showResetForm ? handlePasswordReset : handleForgotPasswordSubmit
            }
            variant="contained"
            disabled={isLoading}>
            {isLoading ? (
              <CircularProgress size={24} />
            ) : showResetForm ? (
              'Reset Password'
            ) : (
              'Send Code'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Login
