import Home from "./pages/Home"
import Login from "./pages/Login"
import "./css/home.scss"
import "./css/login.scss"
import "./css/session.scss"
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom"
import { useContext } from "react"
import { AuthContext } from "./context/AuthContext"
import Join from "./pages/Join"
import { validateToken } from "./service/authService"
import About from "./pages/About"
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import SignUp from "./pages/SignUp"
import SessionTable from "./component/session/SessionTable"
import Session from "./pages/Session"

function App () {
  const { currentUser, currentUserLoading } = useContext(AuthContext)

  const ProtectedRoute = ({ children }) => {
    const location = useLocation()

    if (currentUserLoading) {
      return <div>Loading...</div>
    }

    if (!currentUser) {
      // Redirect to login and save the intended destination
      return <Navigate to="/login" state={{ from: location }} replace />
    }

    return children
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route path="" element={<About />} />
          <Route path="login" element={<Login />} />
          <Route path="about" element={<About />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="session" element={<Session />} />
          <Route path="session/:id" element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          } />
        </Route>
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  )
}

export default App
