import * as React from 'react'
import PropTypes from 'prop-types'
import {
  Tabs,
  Button,
  Tab,
  Typography,
  Box,
  Tooltip,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material'
import CodeIssuePanel from '../topBar/CodeIssuePanel'
import TaskPanel from '../topBar/Task-UnitTest-Panel'
import GroupPanel from '../group/GroupPanel'
import { TestPanelContextProvider } from '../../context/TestPanelContext'
import { SessionContext } from '../../context/SessionContext'
import PresenterPanel from './PresenterPanel'
import { useContext, useEffect, useState } from 'react'
import { showToast } from '../commonUnit/Toast'
import { ContentCopy } from '@mui/icons-material'
import { AuthContext } from '../../context/AuthContext'
import {
  groupingInSession,
  setSessionRegrouping,
  setSessionGroupRound,
  setSessionEnableChat,
  setSessionEnableChatAndCloseSession,
} from '../../service/sessionService'
import { SubmissionsContext } from '../../context/SubmissionsContext'
import {
  getPassrateOfClass,
  getTotalPassrateOfClass,
} from '../../service/submissionService'
import AudioGroupPanel from '../audioSTU/group/AudioGroupPanel'
import CustumPromptPanel from '../chat/AIChat/CustomPromptPanel'
import { AutoGroupingContext } from '../../context/AutoGroupingContext'
import { CurrentStepContext } from '../../context/CurrentStepContext'

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0)
  const { session, setLink } = useContext(SessionContext)
  const { currentUser } = useContext(AuthContext)
  const { submissions } = useContext(SubmissionsContext)
  const [chatEnable, setChatEnable] = useState(false)
  const {
    autoGroupingRate,
    setAutoGroupingRate,
    classPassrate,
    setClassPassrate,
  } = useContext(AutoGroupingContext)
  const { setActiveStep } = useContext(CurrentStepContext)

  const Grouping = async () => {
    if (!session.stu_num || session.stu_num <= 2) {
      showToast('Please wait for more students', 'warning')
      return
    }
    await setSessionRegrouping(session.id, true)
    await groupingInSession(session, 'passrate').then((res) => {
      if (res) {
        showToast('Grouping successfully.', 'success')

        setSessionRegrouping(session.id, false)
        setSessionGroupRound(session.id, 1)
      } else {
        showToast('Grouping failed.', 'error')
        setSessionRegrouping(session.id, false)
        return
      }
    })
    await setSessionEnableChat(session.id, true)
  }

  useEffect(() => {
    setChatEnable(session?.enable_chat)
  }, [session])

  const handleNext = async () => {
    if (!session.stu_num || session.stu_num <= 1) {
      showToast('Please wait for more students', 'warning')
      return
    }
    try {
      const res = await groupingInSession(session, 'passrate', 2)
      if (res) {
        showToast('Grouping successfully.', 'success')
        setChatEnable(true)
        setActiveStep(1)
      } else {
        showToast('Grouping failed.', 'error')
      }
    } catch (error) {
      showToast('An error occurred while removing the group.', 'error')
      console.error('Error removing group:', error)
    }

    await setSessionEnableChat(session.id, !chatEnable)
  }

  useEffect(() => {
    console.log(submissions)
    console.log(session)
    if (!submissions) return
    if (session?.type == 'Auto Grouping') {
      let passrate = getPassrateOfClass(
        session.stu_num,
        session?.test_list?.length,
        submissions
      )
      console.log(passrate)
      setClassPassrate(passrate)
      if (
        passrate >= autoGroupingRate &&
        session?.group_round == 0 &&
        !session?.regrouping
      ) {
        if (currentUser.role < 3) {
          Grouping()
        }
      }
    }
  }, [submissions, autoGroupingRate, session])

  const handleCopyLink = () => {
    const currentUrl = window.location.href
    const longURL = currentUrl
    const headers = {
      Authorization:
        'Bearer xVtTD1mVA4CnM5sqCReF7w8GtxjItg2FcdEiiwS4cMl0C5FYQ9vYh9ULMO7E',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }

    let body = {
      long_url: longURL,
    }
    navigator.clipboard.writeText(currentUrl)
    showToast('Link copied to clipboard!', 'success', 2000)
    setLink(currentUrl)
    fetch('https://t.ly/api/v1/link/shorten', {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    }).then(function (response) {
      if (response.ok) {
        response.json().then(function (resp) {
          navigator.clipboard.writeText(resp['short_url'])
          setLink(resp['short_url'])

          showToast('Link copied to clipboard!', 'success', 2000)
        })
      } else {
        console.log('resp: ', response)
        navigator.clipboard.writeText(currentUrl)
        showToast('Link copied to clipboard!', 'success', 2000)
        setLink(currentUrl)
      }
    })
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const tabs = [{ label: 'Task and Unit Test', component: <TaskPanel /> }]

  // if(session?.type === 'Vizmental'){
  //   tabs.push({ label: "Custom AI Prompts", component: <CustumPromptPanel /> });
  // }

  tabs.push({
    label: 'Class Performance',
    component: (
      <TestPanelContextProvider>
        <CodeIssuePanel />
      </TestPanelContextProvider>
    ),
  })

  if (session?.type !== 'Vizmental') {
    tabs.push({ label: 'Groups', component: <GroupPanel /> })
  }

  tabs.push({ label: 'Presenter View', component: <PresenterPanel /> })
  useEffect(() => {
    if (!session) return
    setChatEnable(session?.enable_chat)
  }, [session?.enable_chat])

  const handleChatEnableChange = async () => {
    try {
      let currentChatEnableStatus
      if (session?.type === 'Vizmental' && chatEnable) {
        currentChatEnableStatus = await setSessionEnableChatAndCloseSession(
          session?.id,
          !chatEnable,
          'closed with AI chat disabled'
        )
      } else {
        currentChatEnableStatus = await setSessionEnableChat(
          session?.id,
          !chatEnable
        )
      }
      setChatEnable(!!currentChatEnableStatus)
    } catch (error) {
      console.error('Error changing chat enable status: ', error)
    }
  }

  return (
    <Box sx={{ width: '100%', overflowY: 'hidden' }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example">
          {tabs.map((tab, index) => (
            <Tab label={tab.label} {...a11yProps(index)} key={index} />
          ))}
        </Tabs>
        <Box sx={{ display: 'flex', alignItems: 'right' }}>
          {session?.type === 'Vizmental' && (
            <Tooltip
              title={
                <Typography variant="subtitle1">
                  Disabling AI chat will mark the end of the session
                </Typography>
              }>
              <FormControlLabel
                value="top"
                label={
                  <Typography
                    sx={{
                      color: '#1976d2',
                      fontWeight: '500',
                      fontSize: '14px',
                    }}>
                    ENABLE AI CHAT
                  </Typography>
                }
                labelPlacement="start"
                control={
                  <Switch
                    className="switch"
                    checked={chatEnable}
                    onChange={handleChatEnableChange}
                    color="primary"
                  />
                }
              />
            </Tooltip>
          )}
          {session?.type == 'Auto Grouping' && (
            <div style={{ display: 'flex' }}>
              <Typography sx={{ marginY: 'auto', marginRight: '1em' }}>
                Passrate {classPassrate}%
              </Typography>
              <TextField
                sx={{ width: '150px' }}
                label="Grouping Threshold (%)"
                variant="filled"
                type="number"
                value={autoGroupingRate}
                onChange={(e) => {
                  setAutoGroupingRate(e.target.value)
                }}
              />
              {session?.grouped && (
                <FormControlLabel
                  value="top"
                  label="Chat"
                  labelPlacement="start"
                  control={
                    <Switch
                      className="switch"
                      checked={chatEnable}
                      onChange={handleChatEnableChange}
                      color="primary"
                    />
                  }
                />
              )}
              {!session?.grouped && (
                <Button
                  className="action-btn"
                  variant="contained"
                  color="primary"
                  onClick={handleNext}>
                  Group Now
                </Button>
              )}
            </div>
          )}
          <Button
            sx={{ marginRight: '20px', marginLeft: '20px' }}
            variant="outlined"
            startIcon={<ContentCopy />}
            onClick={() => {
              handleCopyLink()
            }}>
            Distribute
          </Button>
        </Box>
      </Box>
      {tabs.map((tab, index) => (
        <CustomTabPanel value={value} index={index} key={index}>
          {tab.component}
        </CustomTabPanel>
      ))}
    </Box>
  )
}
