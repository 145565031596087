import React, { useContext, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'

const SignUp = () => {
  const [err, setErr] = useState(false)
  const navigate = useNavigate()
  const { handleSignUp } = useContext(AuthContext)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const firstname = e.target[0].value
    const middlename = e.target[1].value
    const lastname = e.target[2].value
    const email = e.target[3].value
    const password = e.target[4].value
    const confirmPassword = e.target[5].value

    if (password !== confirmPassword) {
      setErr("Passwords don't match")
      return
    }

    try {
      const userInfo = {
        firstname,
        middlename,
        lastname,
        email,
        password,
      }
      await handleSignUp(userInfo)
      navigate('/')
    } catch (err) {
      console.error(err)
      if (err.response?.status === 400) {
        setErr('Email already exists')
      } else {
        setErr('Something went wrong')
      }
    }
  }

  return (
    <div className="formContainer">
      <div className="formWrapper">
        <span className="logo">VizPI</span>
        {/* <span className="title">Sign Up</span> */}
        <form onSubmit={handleSubmit} className="signupForm">
          <input type="text" placeholder="First Name" />
          <input type="text" placeholder="Middle Name" />
          <input type="text" placeholder="Last Name" />
          <input type="email" placeholder="Email" />
          <input type="password" placeholder="Password" />
          <input type="password" placeholder="Confirm Password" />
          <div className="signupButton">
            <button>Sign up</button>
          </div>
          <p>
            Already have an account? <Link to="/login">Login</Link>
          </p>
          {err && <span className="error">{err}</span>}
        </form>
      </div>
    </div>
  )
}

export default SignUp
